<template>
<a-form :form="form" @submit="handleSubmit" class="p-form">
  <h3 class="form-title" style="">Rooming Preferences</h3>
  <div class="form">        
          <a-form-item class="v-form">
              <label style="color: rgba(0, 0, 0, 0.85);">
              <span style="color:#f5222d;margin-right:4px;display: inline-block;">* </span> 
              Room Types - 1 <span style="font-size:5px;">st</span> Choice :  
              </label>
              <a-select
                v-decorator="[
                    'idRooTyp',
                    {initialValue:datas.idRooTyp, rules: [{ required: true, message: 'please choice !' }] },
                  ]"
                placeholder="choice"
                @change="changeRoomType"
              >
                <a-select-option :data-aid="item.capacity" :value="item.idRooTyp" v-for="(item,index) in roowtype">
                  {{item.roomType}}
                </a-select-option>
                
              </a-select>
              <div style="line-height: 120%;font-size: 12px;">
                  Check Monthly Rental <a href="http://innocell.alliancetgl.com/files/tst.jpg" target="_blank">(download here)</a>

              </div>
           </a-form-item>
           <a-form-item class="v-form">
              <label style="color: rgba(0, 0, 0, 0.85);">
              <span style="color:#f5222d;margin-right:4px;display: inline-block;">* </span> 
              Room Types - 2 <span style="font-size:5px;">nd</span> Choice:
              </label>
              <a-select
                v-decorator="[
                    'idRooTyp2',
                    {initialValue:datas.idRooTyp, rules: [{ required: true, message: 'please choice !' }] },
                  ]"
                placeholder="choice"
              >
                <a-select-option :value="item.idRooTyp" v-for="(item,index) in roowtype">
                  {{item.roomType}}
                </a-select-option>
                
              </a-select>
           </a-form-item>
           <div v-for="(item,k) in customers" :key="k">
	           <a-form-item label="Applicant" >
	           	  <a v-if="k>0" href="javascript:;" @click="removeTenant(k)" title="Delete Item" style="position: absolute;top: -40px;right: 0;float:right;color:#f00;height: 25px;"><a-icon type="close-circle" /></a>	
	              <a-input
	              	  v-model="item.email"
	                  placeholder="Company Email Address"
                    :disabled="k== 0? mdisabled : false"
	                />
	                <a-input
	                  v-model="item.name"
	                  placeholder="Employee Name (Mr/Mrs/Miss)"
                    :disabled="k== 0? mdisabled : false"
	                />
	                <a-input
	                  v-model="item.phoneNumber"
	                  placeholder="Contact No (with country code)"
	                />
	                <a-date-picker v-model="item.dateOfBirth" placeholder = "Date of Birth" style="width: 100%" :data-aid="k"  @change="changeBirthday" />
	               <a-select
                		v-model="item.gender"
                		placeholder="Gender"
              		>
                	<a-select-option :value="0" >
                   	M
                	</a-select-option>   
                	<a-select-option value="1" >
                   	F
                	</a-select-option>   
              </a-select>
	           </a-form-item>
           </div>


           <!--
          <div class="form-group" v-for="(customer,k) in customers">

            <label for="" style="width:100%;">Tenant {{k+1}} <a href="javascript:;" @click="removeTenant(k)" title="Delete Item" style="float:right;color:#f00;"><a-icon type="close-circle" /></a></label>
            <input type="text" value="" class="form-control" placeholder="Company Name" readonly="">
            <input type="text" class="form-control" placeholder="Name" style="margin-top:5px;">
            <input type="text" class="form-control" placeholder="Email Address" style="margin-top:5px;">
          </div>
          -->
          <div style="margin:15px 0;">
              <button type="button" class="btn btn-primary" @click="addTenant"><a-icon type="plus" /> Add Tenant</button>
          </div>
         <div class="tips-form-item"> 
           <a-form-item label="Check in Date">
  	         <a-date-picker style="width: 100%"
              :disabled-date="disabledStartDate"
              v-decorator="['dateOfAdmission',{initialValue:datas.dateOfAdmission, rules: [{ required: true, message: 'Please input Check in Date' }] }]" @change="changeStartdate" />
            
	         </a-form-item>
           <span class="tips-item">
              <a-tooltip placement="bottomRight">
                <a-icon type="question-circle" class="tips-item"/>
                <template slot="title">
                  Check-in date must be before 31st August 2021
                </template>
              </a-tooltip>
            </span>
          </div>
          <a-form-item label="Check Out Date">
	      
	        <a-input
          :disabled="datedisabled"
          style="width: 100%" v-decorator="['endDate',{initialValue:datas.endDate,  rules: [{ required: false, message: 'Please input End Date of Stay!' }] }]" @change="changeEnddate" >
                     <a-icon slot="suffix" type="calendar" />

          </a-input>
	      </a-form-item>

       
          <div class="form-group btn-group" >
              <button class="btn btn-primary" @click="previous" type="button">Previous</button>
              <button class="btn btn-info" :disabled="submitDisabled" type="submit"><a-spin :indicator="indicator" v-if="submitDisabled"/> Continue</button>
          </div>
  </div>
</a-form>
</template>

<script>
import jq from 'jquery'
import global from '../../global'
import moment from 'moment/moment'
export default {
    name: 'Rooming',
    props:{
      submitDisabled:'',
    },
    data() {
        return {
           form: this.$form.createForm(this, { name: 'coordinated' }),
           customers:[
              
           ],
           roowtype:[],
           endDate:'',
           dateOfAdmission:'',
           datas:{},
           allowClear:false,
           userInfo:{},
           capacity:1,
           mdisabled:true,
           datedisabled:true,
           indicator:<a-icon type="loading" style="font-size: 16px" spin />,
        }
    },
    methods: {
        handleSubmit(e){
        	e.preventDefault();
            let that = this;      
            this.form.validateFields((err, values) => {
              if (!err) {
              	jq.extend(values,{roommateListStr:JSON.stringify(this.customers)})
              	that.$cookies.set('_FORMDATA3_',values)
              	values.endDate = moment(values.endDate).format("YYYY-MM-DD")
              	values.dateOfAdmission = moment(values.dateOfAdmission).format("YYYY-MM-DD")
                this.$emit("switchForm", values,3) 
              }
            });   
        },
        _step(){
          this.$emit("switchForm", 3) 
        },
        previous(e){
          var datas = this.form.getFieldsValue();
          jq.extend(datas,{roommateListStr:JSON.stringify(this.customers)})
          this.$cookies.set('_FORMDATA3_',datas)
          this.$emit("switchForm",-1,3) 
        },
        addTenant(){
          if(this.capacity <= this.customers.length){
              this.$message.info('Max added: '+this.capacity);
              return ;
          }
          this.customers.push({
                      name:'',
                      email:'',
                      brthday:'',
                      phoneNumber:'',
                      gender:0,
                      dateOfBirth:''
                  })

	      },
	      removeTenant(index){
	          this.customers.splice(index, 1);
	      },
	    _initroowtype(){
          var that = this;
          that.isLoad = true;
          this.axios
              .get(global.api.domain+"forntend/listAllRoomType.do",{},{headers:global.api.headers})
              .then((response) => {
                  that.isLoad = false;
                  if(response.data.code == 0){
                    that.roowtype = response.data.data || [];
                    console.log(that.roowtype)
                  }
                  //that.companys = response.data;
            })
        },
        changeEnddate(e,date){
        	this.endDate = date;
        },
        changeStartdate(e,date){
          this.endDate = "222222";
        	this.dateOfAdmission = date;
          const dateTime = new Date(date);
          if(dateTime.getDate() == 1){
            dateTime.setDate(dateTime.getDate() - 3);
          }else{
            dateTime.setDate(dateTime.getDate() - 1);
          }
          
          dateTime.setFullYear(dateTime.getFullYear() + 1);
          dateTime.setMonth(dateTime.getMonth()+1);
          this.endDate = dateTime.getFullYear()+"-"+dateTime.getMonth()+"-"+dateTime.getDate();
          this.datas.endDate = this.endDate
          console.log(this.endDate)
        },
        changeBirthday(e,date,index){
        	console.log(date)
        	console.log(e)
        	console.log(index)
        },
        disabledStartDate(startValue){
          const endValue = new Date();
          if (!startValue) {
            return false;
          }
          var s = moment(startValue).format("YYYY-MM-DD");
          const startDate = new Date(s);
          if(startDate.getMonth() > 7){
             return true;
          }
          return startValue.valueOf() <= endValue.valueOf()-86399;
        },
        disabledEndDate(endValue){
          return true;
        },
        changeRoomType(e){
          this.roowtype.forEach((item, index) => {
              if(item.idRooTyp == e){
                  this.capacity = item.capacity
              }
          });
          var i = this.customers.length - this.capacity;
          if(i > 0){
              for(var k=i;k>0;k--){
                this.removeTenant(k);
              }
          }
          
        }
    },
    mounted() {
     this.userInfo = this.$cookies.get('_USER_');
     this.customers.push({
            name:this.userInfo.name,
            email:this.userInfo.email,
            brthday:'',
            phoneNumber:this.userInfo.contactNo,
            gender:0,
            dateOfBirth:''
        })
    	if(this.$cookies.isKey('_FORMDATA3_') && this.$cookies.get('_FORMDATA3_') != ''){
            this.datas = this.$cookies.get('_FORMDATA3_')
            if(this.datas.roommateListStr != null && this.datas.roommateListStr != ''){
            	this.customers = JSON.parse(this.datas.roommateListStr)
            }
        }
        this._initroowtype();
    }
}
</script>

<style lang="less">

</style>
