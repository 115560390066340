<template>
<a-form :form="form" @submit="handleSubmit" class="p-form">

  <h3 class="form-title" style="">Company / Organization Particulars</h3>
 
  <div class="form">
     
    <div class="tips-form-item">
      <a-form-item label="Company / Organization Name">
              <a-input
                v-decorator="['companyName', {initialValue:userInfo.companyName,rules: [{ required: true, message: 'Please input Company / Organization Name' }] }]"
                :disabled="mdisabled"
              />
      
    </a-form-item>
    
    </div>
    
    <a-form-item label="Address">
              <a-input
                v-decorator="['address', {initialValue:datas.address,rules: [{ required: true, message: 'Please input Address' }] }]"
              />
              
    </a-form-item>
    <a-row>
      <a-col :span="12">
      <div class="tips-form-item">
      <a-form-item label="Lease/License Period">
        <a-date-picker style="width: 100%" 
               v-decorator="['formDate',{initialValue:datas.formDate, rules: [{required: true, message: 'select form date' }] }]" 
               @change="changeStartdate"
               :disabled-date="disabledStartDate"
               placeholder="From"
               format="YYYY-MM-DD"
               @openChange="handleStartOpenChange"
              />
       </a-form-item>
       <span class="tips-item formdate-tisp">
              <a-tooltip placement="bottomRight">
                <a-icon type="question-circle" class="tips-item"/>
                <template slot="title">
                  Please check with your company on the HKSTP lease period
                </template>
              </a-tooltip>
            </span>
       </div>
      </a-col>
      
      
      <a-col :span="12">
        <div class="pl5 nontitle tips-form-item">
          <a-form-item label="　">
          <a-date-picker style="width: 100%" 
                   v-decorator="['toDate',{initialValue:datas.toDate,rules: [{ required: true, message: 'select to date' }] }]" 
                   @change="changeEnddate"
                   :disabled-date="disabledEndDate"
                   placeholder="To"
                   format="YYYY-MM-DD"
                   @openChange="handleEndOpenChange"
                   :open="endOpen"
                  />
       </a-form-item>
       <span class="tips-item todate-tisp">
              <a-tooltip placement="bottomRight">
                <a-icon type="question-circle" class="tips-item"/>
                <template slot="title">
                  Please check with your company on the HKSTP lease period
                </template>
              </a-tooltip>
            </span>
       </div>
      </a-col>
    </a-row>
    <h4 class="tips-form-item">
       Contact of authorized licensee: 
       <span class="tips-item">
          <a-tooltip placement="bottomRight">
            <a-icon type="question-circle" class="tips-item"/>
            <template slot="title">
              <div>i. Authorized Person is the one who signed HKSTP tenancy agreement </div>
              <div>ii. He/She should be the same person who sign the Declaration Form</div>
            </template>
          </a-tooltip>
        </span>
    </h4>
    <a-row>
      <a-col :span="12">
      <a-form-item label="Full Name">
         <a-input
                v-decorator="['companyContactName', {initialValue:datas.companyContactName,rules: [{ required: true, message: 'Please input Full Name' }] }]"
              />
       </a-form-item>
      </a-col>
      <a-col :span="12">
        <div class="pl5 nontitle">
          <a-form-item label="Position:">
           <a-input
                v-decorator="['companyPosition', {initialValue:datas.companyPosition,rules: [{ required: true, message: 'Please input Position' }] }]"
              />
       </a-form-item>
       </div>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
      <a-form-item label="Tel Number">
         <a-input
                v-decorator="['companyTelno', {initialValue:datas.companyTelno,rules: [{initialValue:datas.companyTelno, required: false, message: 'Please input Tel. No.' }] }]"
              />
       </a-form-item>
      </a-col>
      <a-col :span="12">
        <div class="pl5 nontitle">
          <a-form-item label="Email:">
           <a-input
                v-decorator="['companyEmail', {initialValue:datas.companyEmail, rules: [{required: false, message: 'Please input Email' }] }]"
              />
       </a-form-item>
       </div>
      </a-col>
    </a-row>
  </div>
  
  <h3 class="form-title" style="">Employee Particular</h3>
      <div class="form">
                
           <a-form-item label="Employee Name (Mr/Mrs/Miss)">
                    <a-input
                      readonly
                      v-decorator="['fullName', {initialValue:userInfo.name, rules: [{ required: true, message: 'Please input your full name!' }] }]"
                      :disabled="mdisabled"
                    />
                    
           </a-form-item>
              
          <a-form-item label="Company Email Address">
                    <a-input
                      readonly
                      v-decorator="['email', {initialValue:userInfo.email, rules: [{type: 'email',message:'The input is not valid E-mail!'},{ required: true, message: 'Please input your email' }] }]"
                      :disabled="mdisabled"
                    />
                    
                  </a-form-item>
                  
                
                  
          
          <a-form-item label="Gender">
            <a-select
                v-decorator="[
                    'gender',
                    {initialValue:datas.gender,  rules: [{ required: true, message: 'Please select your Gender!' }] },
                  ]"
                placeholder="Select a Gender"
              >
                <a-select-option :value="k" v-for="(gender,k) in genders">
                  {{gender}}
                </a-select-option>   
              </a-select>
          </a-form-item>
          <a-form-item label="Nationality">
            <a-select
                show-search
                v-decorator="[
                    'nationality',
                    {initialValue:datas.nationality, rules: [{ required: true, message: 'Please select your Nationality!' }] },
                  ]"
                placeholder="Select a Nationality"
              >
                <a-select-option :value="item" v-for="(item, index) in countrys">
                  {{item}}
                </a-select-option>   
              </a-select>
          </a-form-item>
          <a-form-item label="ID/Passport Number">
              <a-input
                v-decorator="['passportNo', {initialValue:datas.passportNo,rules: [{ required: true, message: 'Please input ID/Passport No' }] }]"
              />
              
            </a-form-item>
            
            <a-form-item label="Name of Institution/University">
              <a-input
                v-decorator="['nameOfInstitution', {initialValue:datas.nameOfInstitution,rules: [{ required: false, message: 'Please input Name of Institution/University' }] }]"
              />
              
            </a-form-item>
            <a-form-item label="Contact No (with country code):">
                <a-input
                  readonly
                  v-decorator="['phoneNumber', {initialValue:userInfo.contactNo, rules: [{ required: true, message: 'Please input your contact No (with country code)' }] }]"
                />
                
              </a-form-item>
            <a-form-item label="Employment Period (Start Day): ">
              <a-date-picker style="width: 100%" v-decorator="['birthday',{initialValue:datas.birthday,  rules: [{ required: true, message: 'Employment Period (Start Day):  ' }] }]" 
               @change="changeBrthday"
              />
            </a-form-item>
          <a-form-item label="Position">
              <a-input
                readonly
                v-decorator="['position', {initialValue:userInfo.position,rules: [{ required: false, message: 'Please input Position' }] }]"
              />
              
            </a-form-item>
          <a-form-item label="Principal of Science Park tenant/incubatee">
              <a-select
                    v-decorator="[
                        'principalOfScienceParkTenant',
                        {initialValue:datas.principalOfScienceParkTenant, rules: [{ required: true, message: 'Please choice ' }] },
                      ]"
                    placeholder="Select choice"
                  >
                    <a-select-option value="Yes" >
                     Yes
                    </a-select-option>
                    <a-select-option value="No" >
                     No
                    </a-select-option>
                    
                  </a-select>
              </a-form-item>
              <a-form-item label="Employee of Science Park tenant/incubatee">
              <a-select
                    v-decorator="[
                        'employeeOfScienceParkTenant',
                        {initialValue:datas.employeeOfScienceParkTenant, rules: [{ required: true, message: 'Please choice ' }] },
                      ]"
                    placeholder="Select choice"
                  >
                    <a-select-option value="Yes" >
                     Yes
                    </a-select-option>
                    <a-select-option value="No" >
                     No
                    </a-select-option>
                    
                  </a-select>
              </a-form-item>
          <a-form-item label="Local / Mainland & Overseas">
              <a-select
                    v-decorator="[
                        'isLocal',
                        {initialValue:userInfo.isLocal, rules: [{ required: true, message: 'Please select' }] },
                      ]"
                    placeholder="Select choice"
                  >
                    <a-select-option value="0" >
                     Local
                    </a-select-option>
                    <a-select-option value="1" >
                     Mainland
                    </a-select-option>
                    <a-select-option value="2" >
                      Oversea
                    </a-select-option>
                  </a-select>
              </a-form-item>
          
          
         </a-row>
         <div class="tips-form-item">
            <a-form-item label="Own any residential property in Hong Kong?">
              <a-select
                    v-decorator="[
                        'ownAnyresidentalPropertyInHongKong',
                        {initialValue:datas.ownAnyresidentalPropertyInHongKong, rules: [{ required: true, message: 'Please select ' }] },
                      ]"
                    placeholder="Select choice"
                  >
                    <a-select-option value="Yes" >
                     Yes
                    </a-select-option>
                    <a-select-option value="No" >
                     No
                    </a-select-option>
                    
                  </a-select>
              </a-form-item>
              <span class="tips-item">
                <a-tooltip placement="bottomRight">
                  <a-icon type="question-circle" class="tips-item"/>
                  <template slot="title">
                    To apply accommodation in InnoCell, you must not own any residential property in Hong Kong
                  </template>
                </a-tooltip>
              </span>
          </div>
          <!--
          <a-form-item label="Company Name" >
              <a-select
                v-decorator="[
                    'idCom',
                    {initialValue:datas.idCom, rules: [{ required: true, message: 'Please select' }] },
                  ]"
                placeholder="Select a Company"
              >
                <a-select-option :value="item.idCom" v-for="(item,index) in companys">
                  {{item.companyName}}
                </a-select-option>
                
              </a-select>
            </a-form-item>
        -->
      </div>
    <h3 class="form-title" style="">Intake Assessment <br/>
    <!--<span style="font-size:14px;line-height: 100%;display: block;">
    
    (Point Scoring Mechanism (Local) <a href="https://innocell.hkstp.org/files/PSMlocal.docx" target="_blank">download here</a> / (Overseas/Mainland) <a href="https://innocell.hkstp.org/files/PSMoversea.docx" target="_blank">download here</a>)
    </span>--></h3>
    <div class="form">
      <a-row>
         <a-col :span="24">
            <div class="tips-form-item">
              <a-form-item label="Academic">
                  <a-select
                      v-decorator="[
                          'academicId',
                          {initialValue:datas.academicId, rules: [{ required: true, message: 'Please select' }] },
                        ]"
                      placeholder="Subjects"
                    >
                      <a-select-option :value="item.idScoCri" v-for="(item, index) in academic">
                        
                    {{item.name}}
                      </a-select-option> 
                    </a-select>
                </a-form-item>
                <span class="tips-item">
                  <a-tooltip placement="bottomRight">
                    <a-icon type="question-circle" class="tips-item"/>
                    <template slot="title">
                      STEM-related subject refers collectively to the academic disciplines of Science, Technology, Engineering and Mathematics
                    </template>
                  </a-tooltip>
                </span>
              </div>
         </a-col>
      </a-row>
      <a-form-item label="R&D">
          <a-select
            v-decorator="[
                'rAndDId',
                {initialValue:datas.rAndDId, rules: [{ required: true, message: 'Please select' }] },
              ]"
            placeholder="R&D"
          >
            <a-select-option :value="item.idScoCri" v-for="(item,index) in studyresearch">
              {{item.name}}
            </a-select-option>
            
          </a-select>
        </a-form-item>
        <a-form-item label="Monthly Salary">
            <a-select
                v-decorator="[
                    'incomeId',
                    {initialValue:datas.incomeId, rules: [{ required: true, message: 'Please select' }] },
                  ]"
                placeholder="Income"
              >
                <a-select-option :value="item.idScoCri" v-for="(item, index) in incomelevel">
                  {{item.name}}
                </a-select-option>   
              </a-select>
          </a-form-item>
        <div class="tips-form-item">
          <a-form-item label="Home-SP Travelling Time in Hong Kong" help="">
             <a-select
                v-decorator="[
                            'homeSpTravellingTimeInHongKongId',
                            {initialValue:datas.homeSpTravellingTimeInHongKongId, rules: [{ required: true, message: 'Please Select' }] },
                          ]"
                placeholder="choice"
              >
              <a-select-option :value="item.idScoCri" v-for="(item, index) in travellings">
                        
                    {{item.name}}
                      </a-select-option> 
              </a-select>
              <div class="p-tisp">
                <div class="tisp-table">
                <span>(Living place to Hong Kong Science Park)</span>
                </div>
              </div>
          </a-form-item>
          <span class="tips-item">
              <a-tooltip placement="bottomRight">
                <a-icon type="question-circle" class="tips-item"/>
                <template slot="title">
                  (i)  Choose "Not Applicable" for Overseas / Mainland<br/>
                  (ii) Please check travelling time on the fastest mode of public transportation from your home to HKSP using the search route function of <a href="https://www.hkemobility.gov.hk/en/route-search/pt" target="_blank">https://www.hkemobility.gov.hk/en/route-search/pt</a>

                </template>
              </a-tooltip>
            </span>
        </div>
        <div class="tips-form-item">
          <a-form-item label="Housing Type in Hong Kong">
              <a-select
                  v-decorator="[
                      'housingTypeInHongKongId',
                      {initialValue:datas.housingTypeInHongKongId,rules: [{ required: true, message: 'Please select Type of Housing!' }] },
                    ]"
                  placeholder="Housing Type in Hong Kong"
                >
                  <a-select-option :value="item.idScoCri" v-for="(item, index) in houstypes">
                    {{item.name}}
                  </a-select-option>   
                </a-select>
            </a-form-item>
            <span class="tips-item">
              <a-tooltip placement="bottomRight">
                <a-icon type="question-circle" class="tips-item"/>
                <template slot="title">
                  Choose “Not applicable” for Overseas/Mainland
                </template>
              </a-tooltip>
            </span>
          </div>
        <div class="tips-form-item">
          <a-form-item label="Living Area per capita in Hong Kong">
            <a-select
                v-decorator="[
                    'livingAreaPerCapitaInHongKong',
                    {initialValue:datas.livingAreaPerCapitaInHongKong, rules: [{ required: true, message: 'Please select Living area per capita' }] },
                  ]"
                placeholder="Living Area per capita in Hong Kong"
              >
                <a-select-option :value="item.idScoCri" v-for="(item, index) in livintAreaPerCatia">
                  {{item.name}}
                </a-select-option>   
              </a-select>
          </a-form-item>
          <span class="tips-item">
              <a-tooltip placement="bottomRight">
                <a-icon type="question-circle" class="tips-item"/>
                <template slot="title">
                  (i) Choose “Not applicable” for Overseas/Mainland<br/>
                  (ii) Living Area per capita refers to the average living space (sq.m.) per person at your current apartment 
                </template>
              </a-tooltip>
            </span>
        </div>
        <div class="tips-form-item">
          <a-form-item label="Contribution to the InnoCell Community">
            <a-select
                v-decorator="[
                    'contributionToTheInnocellCommunityId',
                    {initialValue:datas.contributionToTheInnocellCommunityId, rules: [{ required: true, message: 'Please select Contribution to the InnoCell Community' }] },
                  ]"
                placeholder="Contribution to the InnoCell Community"
              >
                <a-select-option :value="item.idScoCri" v-for="(item, index) in contributionIotheInnoCellCommunity">
                  {{item.name}}
                </a-select-option>   
              </a-select>
          </a-form-item>
          <span class="tips-item">
              <a-tooltip placement="bottomRight">
                <a-icon type="question-circle" class="tips-item"/>
                <template slot="title">
                 Willingness to participate in the voluntary work or activtiies of InnoCell 
                </template>
              </a-tooltip>
          </span>
        </div>
      <div class="form-group" style="text-align:right">
          <!--<button class="btn btn-primary" type="button">Save Draft</button> -->
          <button class="btn btn-info" style="width:48%" type="submit">Continue</button>
      </div>
    </div>
    
      <div v-if="isLoad">
        <a-spin />
      </div>
</a-form>
</template>

<script>
import jq from 'jquery'
import global from '../../global'
import moment from 'moment/moment'
export default {
    name: 'Personal',
    data() {
        return {
           form: this.$form.createForm(this, { name: 'coordinated' }),
           countrys:require('../../data/countrys.json'),
           houstypes:[],
           livingareas:[],
           incomelevel:[],
           areas:[],
           studyresearch:[],
           titles:['Mr','Mrs','Ms'],
           genders:['M','F'],
           companys:[],
           isLoad:false,
           academicLevel:[],
           academic:[],
           livintAreaPerCatia:[],
           travellings:[],
           birthday:'',
           datas:{},
           endOpen:false,
           startValue: null,
           endValue: null,
           contributionIotheInnoCellCommunity:[],
           userInfo:{},
           formDate:'',
           toDate:'',
           mdisabled:true,
           moment

        }
    },
    methods: {
        handleSubmit(e){
            e.preventDefault();
            let that = this;      
            this.form.type = 1;
            this.form.validateFields((err, values) => {
              //this.$emit("switchForm", values,1) 
              if (!err) {
                this.$cookies.set('_FORMDATA1_',values);
                values.birthday = this.birthday
                values.formDate = this.formDate
                values.toDate = this.toDate
                values.idCom = this.userInfo.idCom
                this.$emit("switchForm", values,1) 
              }
            });    
            return false;   
        },
        _initCompanys(){
          var that = this;
          that.isLoad = true;
          this.axios
              .get(global.api.domain+"forntend/listAllCompany.do",{},{headers:global.api.headers})
              .then((response) => {
                  that.isLoad = false;
                  if(response.data.code == 0){
                    that.companys = response.data.data;
                  }

                  //that.companys = response.data;
                  console.log(response.data.data)
            })
        },
        _initData(){
          var that = this;
          that.isLoad = false;
          this.axios
              .get(global.api.domain+"forntend/getScoreCriteriaList.do",{},{headers:global.api.headers})
              .then((response) => {
                  that.isLoad = false;
                  if(response.data.code == 0){
                     that.incomelevel = response.data.data.income || [];
                     that.livingareas = response.data.data.livingLocation || [];
                     that.houstypes = response.data.data.housingTypeInHongKong || [];
                     that.areas = response.data.data.livingLocation || [];
                     that.studyresearch = response.data.data.rAdnD || [];
                     that.academicLevel = response.data.data.academicLevel || [];
                     that.academic = response.data.data.academic||[];
                     that.livintAreaPerCatia = response.data.data.livingAreaPerCapitaInHongKong || [];
                     that.travellings = response.data.data.homeSpTravellingTimeInHongKong||[];
                     that.contributionIotheInnoCellCommunity = response.data.data.contributionIotheInnoCellCommunity || [];
                  }
                  console.log(response.data.data)
            })
        },
        changeBrthday(e,date){
            console.log(moment(e).format("YYYY-MM-DD"))
            this.birthday = date
            //this.$cookies.set('_FORMDATA1brthday_',date)
        },
        
        changeStartdate(e,date){
            this.startValue = date
            this.formDate = date;
        },
        changeEnddate(e,date){
            this.endValue = date
            this.toDate = date;

        },
        disabledStartDate(startValue) {
          const endValue = new Date(this.endValue);
          if (!startValue || !this.endValue) {
            return false;
          }
          return startValue.valueOf() > endValue.valueOf();
        },
        disabledEndDate(endValue) {
          const startValue = new Date(this.startValue)
          if (!endValue || !this.startValue) {
            return false;
          }
          
          return startValue.valueOf() >= endValue.valueOf();
        },
        handleStartOpenChange(open) {
          if (!open) {
            this.endOpen = true;
          }
        },
        handleEndOpenChange(open) {
          this.endOpen = open;
        },
    },
    mounted() {
        var user = this.$cookies.get('_USER_');

        if(this.$cookies.isKey('_FORMDATA1_') && this.$cookies.get('_FORMDATA1_') != ''){
            this.datas = this.$cookies.get('_FORMDATA1_');
            console.log(this.datas)
        }
        this.userInfo = this.$cookies.get('_USER_');
        console.log(user)
        this._initCompanys();
        this._initData();
    },
    watch: {
      startValue(val) {
        console.log('startValue', val);
      },
      endValue(val) {
        console.log('endValue', val);
      },
    },
}
</script>

<style lang="less">

</style>
