<template>
<a-form :form="form" @submit="handleSubmit" class="p-form">
  <h3 class="form-title" style="">
  Document Submission
  <p style="font-size: 14px;line-height: 100%;padding:10px 15px;">(Please sign and upload the declaration form. Other supporting documents need to provide upon confirmation on successful offer.)</p>
  </h3>
  
    <div class="form">
        
        <a-form-item label="Valid Work Permit from Non-local Applicant" class="form-group form-group-file">
          <div class="input-file">
          <button type="button" class="btn-file">Choose File</button>
          <span>{{cardIdNotice}}</span>
          <a-input
            type="file"
            accept="image/*,.pdf,.docx,.doc"
            v-decorator="['id', { rules: [{ required: false, message: 'Please upload' }] }]"
            @change="ChooseUpdateID"
            :disabled="mdisbaled"
          />
          </div>
        </a-form-item>
        <a-form-item label="Employment Contract (to indicate applicant’s compensation package and title)" class="form-group form-group-file">
          <div class="input-file">
          <button type="button" class="btn-file">Choose File</button>
          <span>{{proofOfIncome}}</span>
          <a-input
            type="file"
            accept="image/*,.pdf,.docx,.doc"
            v-decorator="['proofOfIncome', { rules: [{ required: false, message: 'Please upload' }] }]"
            @change="ChooseUpdateProofOfIncome"
            :disabled="mdisbaled"
          />
          </div>
        </a-form-item>

        <a-form-item label="Certificate of Graduation" class="form-group form-group-file">
         
          <div class="input-file" >
          <button type="button" class="btn-file">Choose File</button>
          <span>{{academicProof1}}</span>
          <a-input
            type="file"
            accept="image/*,.pdf,.docx,.doc"
            v-decorator="['academicProof1', { rules: [{ required: false, message: 'Please upload' }] }]"
            @change="ChooseUpdateacademicProof1"
            :disabled="mdisbaled"
          />
          </div>
          <div class="input-file" style="margin-top:5px" >
          <button type="button" class="btn-file">Choose File</button>
          <span>{{academicProof2}}</span>
          <a-input
            type="file"
            accept="image/*,.pdf,.docx,.doc"
            @change="ChooseUpdateacademicProof2"
            :disabled="mdisbaled"
          />
          <div class="filenotbutton"></div>
          </div>
          <div class="input-file" style="margin-top:5px"  >
          <button type="button" class="btn-file">Choose File</button>
          <span>{{academicProof3}}</span>
          <a-input
            type="file"
            accept="image/*,.pdf,.doc,.docx"
            @change="ChooseUpdateacademicProof3"
            :disabled="mdisbaled"
          />
          <div class="filenotbutton"></div>
          </div>
          <!--
          <div class="input-file" style="margin-top:5px"  >
          <button type="button" class="btn-file">Choose File</button>
          <span>{{academicProof4}}</span>
          <a-input
            type="file"
            accept="image/*,.pdf,.docx,.doc"
            @change="ChooseUpdateacademicProof4"
          />
          <div class="filenotbutton"></div>
          </div>
          -->
        </a-form-item>
        <a-form-item label="Address Proof  (Bank letter, Utility etc) from Local Applicant" class="form-group form-group-file">
          <div class="input-file">
          <button type="button" class="btn-file">Choose File</button>
          <span>{{addressNotiveProof}}</span>
          <a-input
            type="file"
            accept="image/*,.pdf,.docx,.doc"
            v-decorator="['addressProof', { rules: [{ required: false, message: 'Please upload' }] }]"
            @change="ChooseUpdateAddressProof"
            :disabled="mdisbaled"
          />
          </div>
        </a-form-item>
        <a-form-item label="A passport photo of applicant" class="form-group form-group-file">
          <div class="input-file">
          <button type="button" class="btn-file">Choose File</button>
          <span>{{photo}}</span>
          <a-input
            type="file"
            accept="image/*,.pdf,.docx,.doc"
            v-decorator="['photo', { rules: [{ required: false, message: 'Please upload' }] }]"
            @change="ChooseUpdatephoto"
            :disabled="mdisbaled"
          />
          </div>
        </a-form-item>
        
        <a-form-item class="form-group form-group-file" style="position: relative;">
          <label style="color: rgba(0, 0, 0, 0.85);"><i style="color:#f5222d;margin-right:4px;display: inline-block;">* </i> Signed Declaration Form: <a href='https://innocell.hkstp.org/files/Section5DeclarationforPartA.pdf' target="_blank">(download here)</a></label>
          <div class="input-file">
          <button type="button" class="btn-file">Choose File</button>
          <span>{{SignedDeclarationForm}}</span>
          <a-input
            type="file"
            accept="image/*,.pdf,.doc,.docx"
            v-decorator="['SignedDeclarationForm', { rules: [{ required: true, message: 'Please upload' }] }]"
            @change="ChooseUpdateSignedDeclarationForm"
          />
          
          </div>
          <div class="p-tisp">
            <div class="tisp-table">
              <span>i) </span><span>Authorized Person to sign the declaration form on behalf of the Company should the one who signed HKSTP tenancy agreement</span>
            </div>
            <div class="tisp-table">
            <span>ii)</span><span> Please note that the signature provided in the signed declaration form should be same as Contact of Authorized Person mentioned before in Step </span>
            </div>
          </div>
        </a-form-item>
        <a-form-item>
          <span style="display: inline-block;padding-right: 15px;">Apply for exceptional subsidy? </span>
          <a-checkbox @change="onshowTextChange" :disabled="mdisbaled">
            Yes
          </a-checkbox>

          <div v-if="showText">
            <div style="position: relative;">
                <span style="position: absolute;z-index:1;right:10px;bottom:-5px;">{{textNumber}} / {{textMaxNumber}}</span>
                <a-textarea
                  v-decorator="['note', { initialValue:datas.note,rules: [{ required: false, message: 'Please upload' }] }]"
                  placeholder=""
                  onchange="this.value=this.value.substring(0, 500)"
                  onkeydown="this.value=this.value.substring(0, 500)"
                  onkeyup="this.value=this.value.substring(0, 500)"
                  @input="descInput"
                  :auto-size="{ minRows: 5, maxRows: 10 }"
                />
            </div>
            <p style="line-height: 120%;font-size: 12px;color:#999;">
              Applicant under the incubation programme is entitled to apply for the Special Monthly Rate. The Applicant shall provide justification (about 500 words) stating the reason of exceptional needs for consideration. The offer of the Special Monthly Rate will be subject to HKSTP’s discretion.
            </p>
          </div>
        </a-form-item>
        <div class="form-group btn-group" >
            <button class="btn btn-primary" @click="previous" type="button">Previous</button>
            <button class="btn btn-info" type="submit">Continue</button>
        </div>
    </div>
</a-form>
</template>

<script>
import jq from 'jquery'
import global from '../../global'
export default {
    name: 'Document',
    data() {
        return {
           form: this.$form.createForm(this, { name: 'coordinated' }),
           cardIdNotice:"No File Chosen",
           proofOfIncome:"No File Chosen",
           academicProof1:"No File Chosen",
           academicProof2:"No File Chosen",
           academicProof3:"No File Chosen",
           academicProof4:"No File Chosen",
           photo:"No File Chosen",
           addressNotiveProof:'No File Chosen',
           SignedDeclarationForm:'No File Chosen',
           cardIdFile:{},
           proofOfIncomeFile:null,
           academicProofFile1:null,
           academicProofFile2:null,
           academicProofFile3:null,
           academicProofFile4:null,
           AddressProofFile:null,
           SignedDeclarationFormFile:null,
           photoFile:null,
           cardCheck:true,
           academicProofChacke:true,
           datas:{},
           showText:false,
           textNumber:0,
           textMaxNumber:500,
           mdisbaled:true,
        }
    },
    methods: {
        handleSubmit(e){
            e.preventDefault();
            this.form.validateFields((err, values) => {
              //this.$emit("switchForm", values,2) 
              if (!err) {
                 values.id = this.cardIdFile;
                 values.proofOfIncome = this.proofOfIncomeFile;
                 values.academicProof1 = this.academicProofFile1;
                 values.academicProof2 = this.academicProofFile2;
                 values.academicProof3 = this.academicProofFile3;
                 values.addressProof = this.AddressProofFile;
                 values.photo = this.photoFile;
                 values.SignedDeclaration = this.SignedDeclarationFormFile;
                 //this.$cookies.set('_FORMDATA2_',values);
                 //window.localStorage.setItem('_FORMDATA2_',values.id);
                 this.$emit("switchForm", values,2) 
              }
            })
        },
        previous(e){
          this.$emit("switchForm",-1,2) 
        },
        ChooseUpdateID(e){
          this.cardIdFile = e.target.files[0];
          this.cardIdNotice = e.target.files[0].name
        },
        ChooseUpdateProofOfIncome(e){
          this.proofOfIncome = e.target.files[0].name
          this.proofOfIncomeFile = e.target.files[0]
        },
        ChooseUpdateacademicProof1(e){
          this.academicProof1 = e.target.files[0].name
          this.academicProofFile1 = e.target.files[0]
          this.academicProofChacke = false
        },
        ChooseUpdateacademicProof2(e){
          this.academicProof2 = e.target.files[0].name
          this.academicProofFile2 = e.target.files[0]
          this.academicProofChacke = false
        },
        ChooseUpdateacademicProof3(e){
          this.academicProof3 = e.target.files[0].name
          this.academicProofFile3 = e.target.files[0]
          this.academicProofChacke = false
        },
        ChooseUpdateacademicProof4(e){
          this.academicProof4 = e.target.files[0].name
          this.academicProofFile4 = e.target.files[0]
          this.academicProofChacke = false
        },
        ChooseUpdatephoto(e){
          this.photo = e.target.files[0].name
          this.photoFile = e.target.files[0]
        },
        ChooseUpdateAddressProof(e){
          this.addressNotiveProof = e.target.files[0].name
          this.AddressProofFile = e.target.files[0]
        },
        ChooseUpdateSignedDeclarationForm(e){
          this.SignedDeclarationForm = e.target.files[0].name
          this.SignedDeclarationFormFile = e.target.files[0]
        },
        onshowTextChange(e){
            console.log(e)
            this.showText = e.target.checked;
        },
        descInput(e){
          this.textNumber = e.target.value.length;
          if(this.textNumber > this.textMaxNumber){
            this.textNumber = this.textMaxNumber
          }
          console.log(e.target.value)
        }
    },
    mounted() {
       
    }
}
</script>

<style lang="less">

</style>
