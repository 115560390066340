<template>
  <div class="register applying-form" id="_applying">
  	<div class="box-left" style="">
  		<div class="logo"><img :src="logo" style=""/></div>
  		<div class="prompt">
          <div class="prompt-number">
              <span id="span-1" style="margin-top:0;" class="atv">
                1
              </span>
              <span id="span-2">2</span>
              <span id="span-3">3</span>
              <span id="span-4">4</span>
          </div>
          <div class="prompt-title">
              <span style="margin-top:0;">Company & Personal Details</span>
              <span>
              Document Submission
              <div id="desc-info-2" class="desc-info">Files must be submitted in pdf, jpg, jpeg, tif or png format. Maximum acceptable file size is 200MB.</div>
              </span>
              <span>
              Room Preference
              <div id="desc-info-3" class="desc-info"></div>
              </span>
              <span style="height: 30px;">Submit</span>
          </div>
      </div>
      <!--success-->
      <div class="view-success" style="">
          <div class="prompt-poin">
            <span>✔</span>
          </div>
          <div class="prompt-name">
              APPLICATION SUBMITTED
          </div>
      </div>
      <!--<div><a href="javascipt:;" @click="viewPriceList">VIEW PRICE LIST</a></div>-->
      <br/>

      <div class="p-tisp" style="font-size: 14px;">
      <p style="padding-left: 5px;">&nbsp;&nbsp;&nbsp;Remarks:</p>
      <div class="tisp-table"><span style="color: #333;">i) </span><span style="color: #333;">
      All soft copies of unsuccessful applications/supporting documents will be destroyed upon completion of the admission process.</span></div><div class="tisp-table">

      <span style="color: #333;">ii)</span><span style="color: #333;"> All personal data collected are for HKSTP admission use only. We are committed to safeguarding the privacy in accordance with the Hong Kong Personal Data (Privacy) Ordinance (Chapter 486) under the laws of Hong Kong. We also endeavour to preserve, protect and keep confidential of all Personal Data stored, and/or collected, and/or transferred from you</span></div></div>
  	</div>
  	<div class="form-box">
  		<div class="block-nav">
  			<a href="javascript:;" @click="$store.commit('setGoBackHome',$cookies.get('language'));">Back to Home</a>
        <a href="?lang=en" class="en" >EN</a>
        <a href="?lang=zh-CN" class="cn">简</a>
  			<a href="?lang=zh-HK" class="hk">繁</a>
  			
  		</div>
      <!--Personal-->
  		<div id="personal-form" v-if="type == 1">
          <Personal @switchForm="_step"></Personal>
      </div>
      <!--end Personal-->
      <!--document-->
      <div id="document-form" v-if="type == 2">
          <Document @switchForm="_step"></Document>
      </div>
      <!--end document-->
      <!--rooming-->
      <div id="rooming-form" v-if="type == 100">
          <h3 class="form-title" style="">Rooming Preferences</h3>
          <div class="form">
                <form action='' id="">
                  <div class="form-group">
                    <label for="" style="width:100%;">Preffered Room Type</label>
                     <div class="input-checkbox">
                            <input type="radio" id="type-1" name="type" value="1"/> <label for="type-1">Twin</label>
                            <input type="radio" id="type-2" name="type" value="2"/> <label for="type-2">Solo</label>
                            <input type="radio" id="type-3" name="type" value="3"/> <label for="type-3">Suite</label>
                            <input type="radio" id="type-4" name="type" value="4"/> <label for="type-4">Powerhub</label>
                     </div> 
                  </div>
                  <div class="form-group">
                    <label for="" style="width:100%;">
                    Preffered Zone  <br/>
                    <span>(prices differ, see price list)</span>
                    </label>
                     <div class="input-checkbox">
                            <input type="radio" id="zone-1" name="zone" value="1"/> <label for="zone-1">Low</label>
                            <input type="radio" id="zone-2" name="zone" value="2"/> <label for="zone-2">High</label>
                     </div> 
                  </div>
                  <div class="form-group">
                    <label for="" style="width:100%;">
                    Preffered View    <br/>
                    <span>(prices differ, see price list)</span>
                    </label>
                     <div class="input-checkbox">
                        <input type="radio" id="view-1" name="view" value="1"/> <label for="view-1">Mountain</label>
                        <input type="radio" id="view-2" name="view" value="2"/> <label for="view-2">Building</label>
                        <input type="radio" id="view-3" name="view" value="3"/> <label for="view-3">Sea</label>
                     </div> 
                  </div>
                  <div class="form-group">
                    <label for="" style="width:100%;">Second Choice Room</label>
                     <div class="input-checkbox">
                            <input type="radio" id="room-1" name="room" value="1"/> <label for="room-1">Twin</label>
                            <input type="radio" id="room-2" name="room" value="2"/> <label for="room-2">Solo</label>
                            <input type="radio" id="room-3" name="room" value="3"/> <label for="room-3">Studio</label>
                            <input type="radio" id="room-4" name="room" value="4"/> <label for="room-4">Powerhub</label>
                     </div> 
                  </div>
                  <div class="form-group">
                    <label for="" style="width:100%;">
                      Are you applying for exceptional needs pricing?<br/> <span>if yes please justify your case</span>
                    </label>
                     <div class="input-yes">
                      <input type="checkbox"/><label>Yes</label>
                      <input type="checkbox"/><label> No</label>
                     </div>
                     <div>
                      <textarea type="text" style="width:100%;height:80px;" class="form-control"></textarea>
                     </div>
                  </div>
                  <div class="form-group">
                    <label for="" style="width:100%;">Date of Admission <span>(12 month fixed-term)</span></label>
                     <input type="text" class="form-control"/>
                     
                  </div>
                  <div class="form-group btn-group" >
                      <button class="btn btn-primary" type="submit">Save Draft</button>
                      <button class="btn btn-info" @click="_step" type="button">Continue</button>
                  </div>
                </form>
          </div>
      </div>
      <!--end rooming-->
      <!--review-->
      <div id="review-form" v-if="type == 3">
          <Rooming @switchForm="handleSubmit" :submitDisabled="submitDisabled"></Rooming>
      </div>
      <!--end review-->
      <!--thanks-->
      <div id="thanks-form" v-if="type == 4">
          <div class="form">
               <div style="font-size:18px;">
                  <p>Thank you for your application. We will get in touch with you on the result shortly. Any questions, please email innocell.general@hkstp.org
</p>
               </div>
          </div>
      </div>
      <!--end thanks-->
      
  	</div>
  </div>
</template>
<script>
import Personal from '@/components/form/Personal'
import Document from '@/components/form/Document'
import Rooming from '@/components/form/Rooming'
import jq from 'jquery'
import global from '../global'
export default {
    name: 'Applying',
    components: {
      Personal,
      Document,
      Rooming
    },
    data() {
        return {
           logo:require('../assets/img/Logos@2.png'),
           type:1,
           datas:[],
           isLoad:false,
           customers:[
              {name:'',email:''}
           ],
           formData:{},
           isError:false,
           submitDisabled:false,
        }
    },
    mounted() {
        if(!this.$cookies.isKey('_TOKEN_') || this.$cookies.get('_TOKEN_') == null ){
             window.location.href = "/#/login"
        }
    },
     methods: {
       handleSubmit(vars,type){
          if(vars === -1){
            this._step(-1,3);
            return ;
          }
          jq.extend(this.formData,vars)
          console.log(this.formData)
          var forms = new FormData()
          for(var field in this.formData){
              forms.append(field,this.formData[field])
          }
          let that = this;
          that.isError = false;
          that.submitDisabled = true;
          this.axios.post(global.api.domain+"forntend/application.do?token="+this.$cookies.get('_TOKEN_'), forms, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
          }).then((response) => {
              that.submitDisabled = false;
              if(response.data.code == 0){
                  this.$cookies.remove('_FORMDATA1_')
                  this.$cookies.remove('_FORMDATA2_')
                  this.$cookies.remove('_FORMDATA3_')
                  that._step({},3);
              }else if(response.data.code == 500220){
                  that.$message.error('Please log in first',2).then(()=>{
                              window.location.href =  "/#/login"
                          });
                  
              }else{
                if(response.data.msg != null){
                     that.$message.error('You already have an account, please go back to the login page',2);
                }
              }
          })  
          
       },
      _step(vars,type){
         if(vars === -1){
            switch(type){
              case 2:
                  this.type = 1;
                  document.querySelector("#desc-info-2").style.display="none";
                  break;
              case 3:
                  this.type = 2;
                  break;
              case 1:
                  this.type = 1;
                  break;
            }
            document.querySelector("#span-"+type).classList.remove('atv');
            document.querySelector("#span-"+type).classList.remove('atv-ok');
            document.querySelector("#span-"+type).innerHTML=type;
            document.querySelector("#span-"+this.type).classList.remove('atv-ok');
            document.querySelector("#span-"+this.type).classList.add('atv');
            document.querySelector("#span-"+this.type).innerHTML=this.type;
            return ;
         }
         if(vars != ''){
            jq.extend(this.formData,vars);
         }
         console.log(this.formData)
         var type = type;
         switch(type){
            case 1:
              this.type = 2;
              document.querySelector("#desc-info-2").style.display="block";
              break;
            case 2:
              this.type = 3;
              document.querySelector("#desc-info-2").style.display="none";
              document.querySelector("#desc-info-3").style.display="block";
              break;
            case 3:
              this.type = 4;
              document.querySelector("#_applying").classList.remove('applying-form');
              break;  
            default:
              type = 2;
              this.type = 1;
         }
         document.querySelector("#span-"+type).classList.remove('atv');
         document.querySelector("#span-"+type).classList.add('atv-ok');
         document.querySelector("#span-"+type).innerHTML="✔";
         document.querySelector("#span-"+this.type).classList.add('atv');
      },
      
      selectRoomType(e){
         var options = e.target.options;
         var selectedIndex = e.target.options.selectedIndex;
         if(options[selectedIndex] != undefined && options[selectedIndex].dataset.value != undefined){
            var num = options[selectedIndex].dataset.value*1;
            if(num > 0){
              var customers = [];
              for(var i=1;i<=num;i++){
                customers.push({
                    name:'',
                    email:'',
                })
              }
              //this.customers = customers;
            }
         }
         
      }
    }
}
</script>
<style>
  body{
    background: #fff;
  }
	#app{
		height:99.8%;
	}
	.main-box{
		height:100%;
		
	}
	.content{
		height:100%;
	}
  
@media screen and (max-width:1079px){
	#app{
		background: #F1F1F1;
	}
}
</style>